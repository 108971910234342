.splash {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  display: grid;
  align-items: center;
  padding: 64px;
}

.splash > div {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  height: 534px;
}

.splash.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
}
.splash > #backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.splash > #animation {
  height: 100px;
  z-index: 20;
}

/* Change the animation's fill stroke  */
.splash > #animation g g g path {
  stroke: #808080;
}

.splash ~ header {
  display: none;
}
