@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cobane';
  src: url('../public/assets/fonts/Cobane/Brink\ -\ BR\ Cobane\ Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Cobane';
  src: url('../public/assets/fonts/Cobane/Brink\ -\ BR\ Cobane\ Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Cobane';
  src: url('../public/assets/fonts/Cobane/Brink\ -\ BR\ Cobane\ SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Cobane';
  src: url('../public/assets/fonts/Cobane/Brink\ -\ BR\ Cobane\ Bold.otf');
  font-weight: 700;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',*/
  /*  'Droid Sans', 'Helvetica Neue', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer components {
  main {
    min-height: calc(100vh - 86px - theme(spacing.header-sm));
  }

  main.main--with-big-footer {
    min-height: calc(100vh - 86px - 219px - theme(spacing.header-sm));
  }

  @media screen(md) {
    main {
      min-height: calc(100vh - 86px - theme(spacing.header-md));
    }
    main.main--with-big-footer {
      min-height: calc(100vh - 86px - 219px - theme(spacing.header-md));
    }
  }
}
